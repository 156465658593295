<template>
  <div class="box" 
    v-loading="loading" 
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <div class="box-title">
        <span>马莲滩水厂-水源地水泵累计流量</span>
        <div class="date">
          <div v-show="formInline.cate=='single_day'">
            <span class="date-text">日期</span>
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="date"
              type="date"
              value-format="timestamp"
              size="mini"
              :clearable="false"
              @change="loadData"
              disabled
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <!-- <div style="margin-left: 20px;">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="统计类别">
                <el-select size="mini" v-model="formInline.cate" clearable placeholder="请选择统计类别">
                  <el-option
                    v-for="item in categorys"
                    :label="item.name"
                    :value="item.code"
                    :key="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div> -->
        </div>
      </div>
      <div id="main2" class="main"></div>
      <img class="chart-title" src="../../img/chart_title.png" alt="">
      <img class="chart-bg" src="../../img/chart_bg.png" alt="">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs'

export default {
  data() {
    return {
      loading: false,
      data: [],
      date: null,
      formInline: {
        cate: 'single_day'
      },
      categorys: [
        { code: 'single_day', name: '单日' },
        // { code: 'last_seven_day', name: '最近7天' },
        // { code: 'last_fifteen_day', name: '最近15天' },
        // { code: 'last_month', name: '月' },
        // { code: 'last_quarter', name: '季' },
        // { code: 'last_year', name: '年' },
      ],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d >= n;
        }
      },
    };
  },
  methods: {
    dayjs,
    setChart() {
      var myChart = echarts.init(document.getElementById("main2"), 'dark');
      var option = {
        backgroundColor: '',
        color: ['#60EEFF'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval:0,
            rotate:40
          },
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '工作数据值',
          type: 'bar',
          barWidth: 20,
          data: [],
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#37BBF8' },
              { offset: 1, color: '#3977E6' }
            ])
            // normal: {
              // color: function(params) {
              //   var colorList = [
              //     '#FF7070','#9FE080','#FFDC60','#FF915A','#839be2',
              //     '#e8927d','#9BCA63','#fbe183','#F3A43B','#7ED3F4',
              //     '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
              //     '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
              //     '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
              //     '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66',
                  
              //     '#FF7070','#9FE080','#fbe183','#FF915A','#839be2',
              //     '#e8927d','#9BCA63','#FAD860','#F3A43B','#7ED3F4',
              //     '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
              //     '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
              //     '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
              //     '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66'
              //   ];
              //   return colorList[params.dataIndex];
              // }
            // }
          }
        }]
      };
      this.data.forEach(item => {
        option.xAxis.data.push(item.position+'('+item.waterworks+')')
        option.series[0].data.push(item.value)
      });
      myChart.setOption(option);
      setTimeout(()=>{
        window.onresize = function () {
          myChart.resize();
        }
        window.addEventListener('keydown', function(event) {
          if(event.keyCode===122) {
            myChart.resize();
          }
        })
      },0)
    },
    // 获取昨天0点时间戳
    getYesterTimestamp() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let date = yDate.getDate()-1;
      let yesterday = year+'-'+month+'-'+date
      let yesterdayTime = (new Date(new Date(yesterday).toLocaleDateString())).getTime();
      return yesterdayTime
    },
    //查询单个设备工作数据
    loadData() {
      this.loading = true;
      this.$ajax.post('iotSingleEquipmentWorkData', {
        equipmentBrandCode: "SIN-1158S",//设备品牌Code
        dateTime: this.date,//指定日期,默认昨天0点时间戳
      }).then(res => {
        this.data = res.data
        this.setChart()
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    }
  },
  mounted() {
    this.date = this.getYesterTimestamp()
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 600px;
  margin: 90px auto 0;
  z-index: 2;
}
.box-title {
  color: #FFF;
  font-size: 20px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 25px;
  left: 40px;
  z-index: 1;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
}
.box {
  overflow: hidden;
  background-color: #04102c;
  position: relative;
  border-radius: 10px;
  height: 831px;
  .chart-title {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 28px auto 0;
    z-index: 0;
  }
  .chart-bg {
    width: 98%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
  .content {
    border-radius: 10px;
    padding-bottom: 16px;
    .search {
      overflow: hidden;
    }
    .box-content {
      padding-bottom: 10px;
      >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: 78px;
        color: #333;
        box-sizing: border-box;
      }
      .imgs {
        width: 48px;
        height: 48px;
        background: rgb(71, 154, 202);
        border-radius: 5px;
        text-align: center;
        line-height: 48px;
        color: #FFF;
        font-size: 24px;
        overflow: hidden;
        position: relative;
      }
      .imgs:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 0.3s, opacity 0.5s;
      }
      .imgs:active:after {
        transform: scale(0, 0);
        opacity: 0.6;
        transition: 0s;
      }
      .bg-colors1 { background-color: #FFDC60; }
      .bg-colors2 { background-color: #7ED3F4; }
      .bg-colors3 { background-color: #FF915A; }
      .bg-colors4 { background-color: #9FE080; }
      .bg-colors5 { background-color: #FF7070; }
      .bg-colors6 { background-color: #5C7BD9; }
      .title {
        font-size: 14px;
        color: #767779;
      }
      .data {
        font-size: 20px;
        margin-top: 8px;
        color: #202020;
      }
    }
  }
  .date {
    display: flex;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 10;
    .date-text {
      font-size: 12px;
      font-weight: normal;
      color: #FFF;//#767779
      margin-right: 10px;
      user-select: none;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
/deep/.el-form-item__content {
  line-height: 50px;
}
/deep/.el-form-item__label {
  font-size: 12px;
  font-weight: normal;
  color: #FFF;//#767779
}
</style>
<template>
  <div class="box">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else style="height:100%;">
      <div class="table-tree">
        <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
          <div class="tree-title">
            <div>
              <i class="iconfont icon-peizhi" style="margin-right:10px;"></i>统计图表
            </div>
          </div>
          <div v-if="configProject.length>0">
            <div class="tree-park" v-for="(item,index) in configProject" :key="index">
              <div class="park-title" :class="current==item.id?'bright':''" @click.prevent="configToggle(item.id)">
                <span class="class-icon iconfont icon-shujufenxi1" :style="current==item.id?'color:#FFF;':'color:#FFF;'"></span>
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
          <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
        </div>
        <div class="chart-size" style="width:100%;height:100%;">
          <box1 v-if="current==1"/>
          <box2 v-if="current==2"/>
          <box3 v-if="current==3"/>
          <box4 v-if="current==4"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import box1 from './box1'
import box2 from './box2'
import box3 from './box3'
import box4 from './box4'

export default {
  components: {
    box1,
    box2,
    box3,
    box4,
  },
  data() {
    return {
      loading: false,
      treeLoading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
 
      roleId: 0,
      configProject: [
        {id: 1,name: '水源地瞬时流量'},
        {id: 2,name: '水源地累计流量'},
        {id: 3,name: '水源地流量趋势图'},
        {id: 4,name: '水源地总累计流量'},
      ],
      current: 0,
      supplierCode: null,
      equipmentBrandCode: null,
      equipmentName: null,
    }
  },
  methods: {
    // 切换组态项目
    configToggle(id) {
      this.current = id
      // sessionStorage.setItem('configProjectId', projectId)
      // this.loadListData()
    },
  },
  mounted() {
    this.current = 1
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 0 !important;
  height: 100%;

}
.table-tree {
  display: flex;
  height: 100%;
  background-color: #04102c;
  .tree {
    min-width: 300px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #04102c;
  color: #FFF;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
  padding: 3px 10px;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
  color: #FFF;
  transition: all .2s ease;
}
.tree-classify {
  line-height: 35px;
}
.classify-title {
  padding-left: 50px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #3977E6;
  color: #FFF;
  border-radius: 5px;
}
</style>